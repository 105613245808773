import { Container, Stack, TextField } from "@mui/material";
import { useState } from "react";

const App = () => {
  const [learn, setLearn] = useState<string>("");
  const [know, setKnow] = useState<string>("");
  const [start, setStart] = useState<string>("");

  return (
    <Container>
      <Stack pt={4}>
        <form>
          <Stack gap={2}>
            <TextField
              label="What do you want to learn"
              onChange={(e) => setLearn(e.currentTarget.value)}
            />
            <TextField
              label="What do you already know"
              onChange={(e) => setKnow(e.currentTarget.value)}
            />
            <TextField
              label="What do you want to start with"
              onChange={(e) => setStart(e.currentTarget.value)}
            />
          </Stack>
        </form>
        <div>
          <h2>Preview</h2>
          <p>
            I would like to learn [{learn}]. You will now act as my teacher.
            When asked questions you will provide real world examples and
            reference official documentations. I have knowledge about [{know}],
            so make sure to provide comparisons that will help me understand.
            Let's begin by detailing how to [{start}].
          </p>
        </div>
      </Stack>
    </Container>
  );
};

export default App;
